import * as React from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import Logo from "../components/Logo"

const NotFoundPage = () => {
	React.useEffect(() => {
		navigate("/404/")
	})

	return (
		<>
			<Helmet>
				<title>Google Feud - 404</title>
			</Helmet>
			<Logo />
			<h2>404 Page</h2>
			<p>Page not found.</p>
		</>
	)
}
export default NotFoundPage
